import React from 'react'
import App from 'App'
import { SITE_LINK_PUBLISHING } from 'consts'
import SEO from 'components/SEO'
import Footer from 'components/Footer'
import imageHeader from 'static/images/integrations/google/header_picture.webp'
import imageToolManage from 'static/images/integrations/google/tools_to_manage.webp'
import imageText1 from 'static/images/integrations/google/image_text_1.webp'
import imageText2 from 'static/images/integrations/google/image_text_2.webp'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import { Flex } from 'components/Layout'
import PageHeader from './components/PageHeader'
import ToolManageSection from './components/ToolManageSection'
import TextImageSection from './components/TextImageSection'
import CoreFeaturesSection from './components/CoreFeaturesSection'
import FaqSection from './components/FaqSection'
import GetStartedSection from '../GetStartedSection'

const NETWORK = 'Google Business'

const TEXT_IMAGES = [
  {
    header: {
      title: `Simplify Content Development & Profile Posting`,
      matches: [],
    },
    textFirst: false,
    image: imageText1,
    items: [
      {
        title: 'Easily Create & Deliver Posts',
        description: `Draft posts for one or many ${NETWORK} Listings — upload videos, attach images from a Media Library, apply targeting and add tags.`,
      },
      {
        title: 'Manage the Content Calendar',
        description: `Easily review and manage ${NETWORK} content from a central publishing calendar—quickly filter by tag, profile and date.`,
      },
      {
        title: 'Seamlessly With Approval Workflow',
        description:
          'Vista’s built-in approval workflow is perfect for legal, compliance, agency collaboration or peer review.',
      },
    ],
    linkText: 'MORE ON PUBLISHING',
    link: SITE_LINK_PUBLISHING,
  },
  {
    header: {
      title: `Efficiently Manage Content & Reviews as a Team`,
      matches: [],
    },
    textFirst: true,
    image: imageText2,
    items: [
      {
        title: `Manage the Social Inbox as a Team`,
        description:
          'Prevent duplication of efforts with team tools—mark as complete, view audit trails and track real-time progress.',
      },
      {
        title: 'Stay Productive With Intuitive Workflows',
        description: `Streamline ${NETWORK} Listing management with message tagging, task routing and real-time status updates for team wide visibility.`,
      },
      {
        title: 'Easily Customize Page Access & Governance',
        description:
          'Organize teams and Pages to fit your needs—great for agencies, multi-location or global businesses.',
      },
    ],
  },
]

const IntegrationsSocial = () => {
  return (
    <App fullHeader>
      <SEO
        title={`${NETWORK} management tools for business`}
        description={`Dynamically engage your audience with a powerful set of tools to manage your ${NETWORK} Listings and Reviews`}
        path="/integrations/google/"
      />

      <PageHeader
        network={NETWORK}
        headerText={`${NETWORK} management tools for business`}
        description={`Dynamically engage your audience with a powerful set of tools to manage your ${NETWORK} Listings and Reviews`}
        image={imageHeader}
      />

      <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
        <G2FeaturesComponent />
      </Flex>

      <ToolManageSection
        network={NETWORK}
        headerText={`Tools to Manage Multiple ${NETWORK} Listings`}
        description={`Vista Social’s ${NETWORK} integration includes publishing, reputation and response management tools for publishing content, reviews and rich analytics. Plan content, engage your audience, collaborate as a team and measure efforts from a single platform.`}
        image={imageToolManage}
        tools={[
          {
            title: `Create Engaging ${NETWORK} Content`,
            description: `Plan, create and publish engaging posts with a centralized content calendar, intuitive workflows and creative tools for ${NETWORK} publishing.`,
          },
          {
            title: 'Manage Conversations & Build Relationships',
            description:
              'Never miss a comment, wall post, Review or Messenger conversation with a unified inbox and powerful social CRM tools for engagement.',
          },
          {
            title: `Benchmark Your ${NETWORK} Performance`,
            description: `Gather paid and organic insights for one or many ${NETWORK} Pages to measure fan growth, analyze engagement and track post performance.`,
          },
        ]}
      />

      <TextImageSection textImages={TEXT_IMAGES} />

      <CoreFeaturesSection headerTitle={`Take your ${NETWORK} Marketing to the Next Level`} matches={[NETWORK]} />

      <FaqSection />

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default IntegrationsSocial
